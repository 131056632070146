import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Esto agrega un desplazamiento suave
    });
  };

  return (
    <footer>
      <Link to={"/version"} onClick={scrollToTop}>
        Versiones
      </Link>
      <a href='https://www.agenciaadhoc.com' target='_blank' rel="noopener noreferrer">
        www.agenciaadhoc.com
      </a>
    </footer>
  )
} 

export default Footer
