import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BASE_URL from '../../../config/config';

const AdminPrePanel = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [longitudUsuarios, setLongitudUsuarios] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/todos-usuarios`);
        const usuarios = response.data.usuarios;
        setUsuarios(usuarios);
        setLongitudUsuarios(usuarios.length);
      } catch (error) {
        console.error('Error al obtener los usuarios:', error);
      }
    };

    const fetchTotalTickets = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/tickets/total`);
        const tickets = response.data.tickets; // Supone que "tickets" es un array de objetos.
        setTotalTickets(tickets.length); // Cuenta el número de tickets.
      } catch (error) {
        console.error('Error al obtener los tickets:', error);
      }
    };

    fetchUsuarios();
    fetchTotalTickets();
  }, []);

  return (
    <div className='admin-prepanel'>
        <Link to={'/dashboard/tickets-manager'} className='prepanel-card'>
            <h3>Gestionar Tickets</h3>
            <p className='prepanel-number'>Responde y administra tickets por departamento.</p>
            <p>Tickets totales: {totalTickets}</p>
            <button>Ir al panel</button>
        </Link>
        <Link to={'/dashboard/users-manager'} className='prepanel-card'>
            <h3>Gestionar Usuarios</h3>
            <p className='prepanel-number'>Administra y verifica los usuarios de la plataforma.</p>
            <p>Usuarios: {longitudUsuarios}</p>
            <button>Ir al panel</button>
        </Link>
         <a className='prepanel-card coming-soon-card'>
            <h3>Ticket Temporal</h3>
            <p>Permite crear un ticket para un usuario sin acceso al soporte.</p>
            <button>Próximamente</button>
        </a>
    </div>
  );
};

export default AdminPrePanel;
