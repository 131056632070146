import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';


const Inicio = () => {
  const [soporteDisponible, setSoporteDisponible] = useState(false);
  const [diaSemana, setDiaSemana] = useState(null); // Definir diaSemana como estado
  const token = localStorage.getItem("AuthToken");
  const logged = token ? true : false;
  
  const navigate = useNavigate();

  const handleCreateTicket = (department) => {
    window.scrollTo(0, 0);
    if (logged) {
      navigate('/nuevo-ticket', { state: { department } });
    } else {
      navigate('/iniciar-sesion');
    }
  };

  useEffect(() => {
    const ahora = new Date();
    const diaSemanaActual = ahora.getDay(); // 0 (Domingo) a 6 (Sábado)
    const hora = ahora.getHours();
    
    // Verificar si es Lunes a Jueves entre las 8:00 am y las 16:00 pm
    const esLunesAJueves = diaSemanaActual >= 1 && diaSemanaActual <= 4;
    const esViernes = diaSemanaActual === 5;
    const esHoraHabil = hora >= 8 && ((esViernes && hora < 15) || (!esViernes && hora < 16));

    if ((esLunesAJueves || esViernes) && esHoraHabil) {
      setSoporteDisponible(true);
    } else {
      setSoporteDisponible(false);
    }

    // Establecer el valor de diaSemana
    setDiaSemana(diaSemanaActual);
  }, []);

  return (
    <main className='inicio'>
      <div className='site-width'>
        <div className='intro'>
          <div className="intro-text">
            <h1>El soporte técnico más cercano <br/>para nuestros clientes</h1>
            <p>Haznos saber tu causa. Crea un ticket y sigue el progreso desde esta misma web.</p>
          </div>
          <div className="intro-info">
            <div className="support-time">
              <img src="/images/servicio-al-cliente.svg" alt="Horario de soporte" />
              <div className="support-text">
                <p>Soporte Técnico</p>
                {/* Mostrar "Disponible" o "No Disponible" basado en el estado */}
                {soporteDisponible ? <p className='disponible'>Disponible</p> : <p className='no-disponible'>No disponible</p>} 
              </div>
            </div>
            <div className="office-time">
              <img src="/images/reloj-azul.svg" alt="Horario de oficina" />
              <div className="office-text">
                <p>Horario de Oficina</p>
                <p>8:00 a.m. - {soporteDisponible && diaSemana !== 5 ? '16:00 p.m.' : '15:00 p.m.'} (CET)</p>
              </div> 
            </div>
          </div>
        </div>
        <div className="departments">
        <div className="department">
            <img src="/images/codigo-cuadrado.svg" alt="Desarrollo" />
            <h2>Dpto. de Desarrollo</h2>
            <p>Si tu cuestión tiene que ver con desarrollo web.</p>
            <button 
              className='token-ticket' 
              onClick={() => handleCreateTicket('desarrollo')}
            >
              {logged ? 'Crear ticket' : 'Inicia sesión para crear un ticket'}
            </button>
          </div>
          <div className="department">
            <img src="/images/pincel-de-lapiz.svg" alt="Diseño" />
            <h2>Dpto. de Diseño</h2>
            <p>Si tu cuestión tiene que ver con diseño gráfico.</p>
            <button 
              className='token-ticket' 
              onClick={() => handleCreateTicket('diseno')}
            >
              {logged ? 'Crear ticket' : 'Inicia sesión para crear un ticket'}
            </button>
          </div>
          <div className="department">
            <img src="/images/descubrir.svg" alt="marketing" />
            <h2>Dpto. de Marketing</h2>
            <p>Si tu cuestión tiene que ver con marketing digital.</p>
            <button 
              className='token-ticket' 
              onClick={() => handleCreateTicket('marketing')}
            >
              {logged ? 'Crear ticket' : 'Inicia sesión para crear un ticket'}
            </button>
          </div>
          <div className="department">
            <img src="/images/trofeo.svg" alt="redes sociales" />
            <h2>Dpto. de Redes Sociales</h2>
            <p>Si tu cuestión tiene que ver con redes sociales.</p>
            <button 
              className='token-ticket' 
              onClick={() => handleCreateTicket('redes_sociales')}
            >
              {logged ? 'Crear ticket' : 'Inicia sesión para crear un ticket'}
            </button>
          </div>
        </div>
      </div>
      <div className='bg-comunidad'>
        <div className="site-width">
          <div className="comunidad">
            <img src="images/imagen-comunidad.svg" alt="Imagen Comunidad" />
            <div className='texto-comunidad'>
              <h2>Juntos construimos <span>comunidad</span></h2>
              <p>Olvida los correos perdidos en la bandeja de entrada y las conversaciones interminables por correo electrónico.</p>
              <p>En nuestro centro de soporte, trabajamos para hacerte la vida más fácil y para ofrecerte la mejor atención al cliente.</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Inicio;
