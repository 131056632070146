import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Verificacion from '../../components/Verificacion';
import AdminPrePanel from './admin/AdminPrePanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js'; // Importa la librería para encriptar
import BASE_URL from '../../config/config';

const Dashboard = () => {
  const [cantidadTickets, setCantidadTickets] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [activeTab, setActiveTab] = useState('historial');
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage, setTicketsPerPage] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [selectedDept, setSelectedDept] = useState('');
  const [dashboardContent, setDashboardContent] = useState('dashboard');
  const token = localStorage.getItem("AuthToken");

  /* Actualizar Ajustes UseStates */
  
  let tokenInfo = {};
  if (token) {
    tokenInfo = jwtDecode(token);
  }
  const [nombre, setNombre] = useState(tokenInfo.nombre || '');
  const [apellidos, setApellidos] = useState(tokenInfo.apellidos || '');
  const [email, setEmail] = useState(tokenInfo.email || '');
  const [telefono, setTelefono] = useState(tokenInfo.telefono || '');
  const [contrasena, setContrasena] = useState('');
  const [webs_del_cliente, setWebs_del_cliente] = useState(tokenInfo.webs_del_cliente || '');

  const encryptPassword = (password) => {
    return CryptoJS.MD5(password).toString(); // Convierte la contraseña a MD5
  };

  const handleSaveProfile = async () => {
    let encryptedPassword = contrasena ? encryptPassword(contrasena) : ''; // Encripta la contraseña si existe
    
    const updatedData = {
      nombre,
      apellidos,
      email,
      telefono,
      ...(contrasena && { contrasena: encryptedPassword }), // Enviar la contraseña encriptada si no está vacía
      webs_del_cliente
    };


    try {
      const response = await axios.put(`${BASE_URL}/usuario/update/${tokenInfo.usuario}`, updatedData, {
        headers: {
          Authorization: token // Asegúrate de enviar el token de autenticación
        }
      });
      
      if (response.status === 200) {
        toast.success('Perfil actualizado con éxito.');
        handleLogout();
      } else {
        toast.error('Hubo un problema al actualizar el perfil.');
      }
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      toast.error('Ocurrió un error al actualizar el perfil.');
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("AuthToken");
    window.location.reload();
  };

  const handleEditProfile = () => {
    // Al hacer clic en "Editar perfil", cambiar el contenido a la vista de perfil
    setDashboardContent('editProfile');
  };

  const handleGoBack = () => {
    // Al hacer clic en "Mis secciones", volver al dashboard
    setDashboardContent('dashboard');
  };
  let profilePicUrl = "/images/usuario.svg";
  if (tokenInfo.fotoPerfil && tokenInfo.fotoPerfil !== "none") {
    profilePicUrl = tokenInfo.fotoPerfil;
  }

  useEffect(() => {
    const fetchCantidadTickets = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/tickets/cantidad/${tokenInfo.usuario}`);
        if (response.status === 200) {
          setCantidadTickets(response.data.cantidad);
        } else {
          console.error('Error al obtener la cantidad de tickets:', response.data.message);
        }
      } catch (error) {
        console.error('Error al obtener la cantidad de tickets:', error);
      }
    };

    fetchCantidadTickets();
  }, [tokenInfo.usuario]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/tickets/total/${tokenInfo.usuario}?page=${currentPage}&limit=${ticketsPerPage}`);
        if (response.status === 200) {
          const sortedTickets = response.data.tickets.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setTickets(sortedTickets);
          filterTickets(sortedTickets);
        } else {
          console.error('Error al obtener los tickets:', response.data.message);
        }
      } catch (error) {
        console.error('Error al obtener los tickets:', error);
      }
    };

    if (activeTab === 'historial') {
      fetchTickets();
    }
  }, [activeTab, currentPage, ticketsPerPage, tokenInfo.usuario]);

  const filterTickets = (tickets) => {
    const filtered = tickets.filter(ticket =>
      (ticket.asunto.toLowerCase().includes(searchText.toLowerCase()) || 
      ticket.identificador.toString().includes(searchText)) &&
      (selectedDept === '' || ticket.dpto.toLowerCase() === selectedDept.toLowerCase())
    );
    setFilteredTickets(filtered);
  };
  

  useEffect(() => {
    filterTickets(tickets);
  }, [searchText, tickets, selectedDept]);

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);

  if (tokenInfo.estado === "verificar") {
    return <Verificacion />;
  }

  const renderContent = () => {
    if (dashboardContent === 'editProfile') {
      return (
        <div className="edit-profile-section">
              <h2>Editar Perfil</h2>
              <div className="grid-campos-ajustes">
                <div className="campo-ajuste">
                  <label htmlFor="usuario">Usuario</label>
                  <p>El nombre de usuario sirve para identificar todas tus acciones en la plataforma. ¿Problemas con tu usuario? Contáctanos directamente por teléfono al <a href='tel:+34 856 901 276'>856 901 276</a> marcando la extensión #2 (Desarrollo).</p>
                  <input type="text" name="usuario" id="usuario" value={'@'+tokenInfo.usuario} disabled />
                </div>
                <hr />
                <div className="texto-edit-profile">
                  <h3>Información Personal</h3>
                  <p>Esta información sirve para dirigirnos hacia tí y contactar contigo en caso necesario de manera correcta.</p>
                </div>
                <div className="campo-ajuste">
                  <label htmlFor="nombre">Nombre</label>
                  <input type="text" name="nombre" id="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                </div>
                <div className="campo-ajuste">
                  <label htmlFor="apellidos">Apellidos</label>
                  <input type="text" name="apellidos" id="apellidos" value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                </div>
 
                <div className="campo-ajuste">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="campo-ajuste">
                  <label htmlFor="telefono">Teléfono</label>
                  <input type="text" name="telefono" id="telefono" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                </div>
                <div className="campo-ajuste">
                  <label htmlFor="webs_del_cliente">Sitios en mantenimiento</label>
                  <p>En caso de ser más de uno, separe sus dominios con comas.</p>
                  <input type="text" name="webs_del_cliente" id="webs_del_cliente" value={webs_del_cliente} onChange={(e) => setWebs_del_cliente(e.target.value)} />
                </div>
                <hr />
                <div className="texto-edit-profile">
                  <h3>Seguridad</h3>
                  <p>Modifica el acceso a tu cuenta.</p>
                </div>
                <div className="campo-ajuste">
                  <label htmlFor="contrasena">Contraseña</label>
                  <input type="password" name="contrasena" id="contrasena" value={contrasena} onChange={(e) => setContrasena(e.target.value)} />
                </div>
                <div className="adhoc-warning">
                 <p>AVISO: Al guardar unos nuevos ajustes, la sesión se cerrará. (Si el campo de contraseña está vacío no se guardará).</p>
                </div>
                <button onClick={handleSaveProfile}>Guardar perfil</button>
              </div>
              
              {/* Componente para manejar los toasts */}
              <ToastContainer />
            </div>
      );
    }

    return (
      <>
        <h2>Bienvenido, {tokenInfo.nombre}</h2>
        {
          tokenInfo.role === "admin" ? 
          <p>Administra tickets de manera global y gestiona clientes.</p>
          : 
          <p>Aquí podrás visualizar tus tickets a lo largo de tu estancia en la agencia.</p>
        }

        {tokenInfo.role === "admin" ? <AdminPrePanel /> : <div className="tabcontent">
          <div className='grid-historial'>
            <div className="filtro-tickets">
              <input type="text" placeholder="Búsqueda por identificador o asunto..." value={searchText} onChange={(e) => setSearchText(e.target.value)} />
              <select value={selectedDept} onChange={(e) => setSelectedDept(e.target.value)}>
                <option value="">Todos los departamentos</option>
                <option value="no_especificado">Sin Especificar</option>
                <option value="diseno">Diseño</option>
                <option value="marketing">Marketing</option>
                <option value="desarrollo">Desarrollo</option>
                <option value="redes_sociales">Redes Sociales</option>
              </select>
            </div>
            <div className='table-overflow'>
              <table className='tickets-table'>
                <thead>
                  <tr>
                    <th>Identificador</th>
                    <th>Asunto</th>
                    <th>Dpto</th>
                    <th>Estado</th>
                    <th>Prioridad</th>
                    <th>Fecha</th>
                    <th>Asignado a</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTickets.length === 0 ? (
                    <tr>
                      <td colSpan="10" className='no-tickets'>No se han encontrado tickets</td>
                    </tr>
                  ) : (
                    currentTickets.map((ticket, index) => (
                      <tr key={index}>
                        <td><Link to={`/ticket/${ticket.identificador}`}>#{ticket.identificador}</Link></td>
                        <td>
                          <Link to={`/ticket/${ticket.identificador}`}>
                            {ticket.asunto.split(' ').slice(0, 5).join(' ')}{ticket.asunto.split(' ').length > 5 ? '...' : ''}
                          </Link>
                        </td>
                        <td>
                        {
                        ticket.dpto === 'no_especificado' ? <p className='no-especificado'>No especificado</p> :
                        ticket.dpto === 'desarrollo' ? <p className="desarrollo">Desarrollo</p> :
                        ticket.dpto === 'diseno' ? <p className="diseno">Diseño</p> :
                          ticket.dpto === 'marketing' ? <p className="marketing">Marketing</p> :
                          ticket.dpto === 'redes_sociales' ? <p className="redes-sociales">Redes Sociales</p> :
                          ''
                        }
                        </td>
                        <td>
                        {
                        ticket.estado_del_ticket === 'abierto' ? <p className='abierto'>Abierto</p> :
                        ticket.estado_del_ticket === 'cerrado' ? <p className="cerrado">Cerrado</p> : ''
                        }                          
                        </td>
                        <td>
                        {
                        ticket.prioridad === 'no_urgente' ? <p className='sin-prioridad'>No urgente</p> :
                          ticket.prioridad === 'urgente' ? <p className="alta">Urgente</p> :    
                          ''
                        }
                        </td>
                        <td>{new Date(ticket.createdAt).toLocaleDateString()}</td>
                        <td className='assignee'>
                          {ticket.usuario_encargado === "sin_asignar" ? 
                            <div className='asignado'>
                            <img src="/images/sin_asignar.svg" alt="Sin asignar" />
                            <p>Sin asignar</p>
                            </div>
                            :
                            <div className='asignado'>
                            <img src="/images/asignado.svg" alt={ticket.usuario_encargado} />
                            <p>{ticket.usuario_encargado}</p>
                            </div>
                          }
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            {filteredTickets.length !== 0 && (
              <div className="pagination">
                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Anterior</button>
                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastTicket >= filteredTickets.length}>Siguiente</button>
              </div>
            )}
          </div>
        </div>}
      </>
    );
  };

  return (
    <main className='site-width'>
      <div className="dashboard-grid">
        <div className='profile-and-menu'>
          <div className='profile'>
            <img src={profilePicUrl} alt="Usuario" className='profile-pic'/>
            {tokenInfo.nombre && tokenInfo.apellidos && (
              <p className='profile-name'>{tokenInfo.nombre} {tokenInfo.apellidos}
              <br/>
              <span>@{tokenInfo.usuario}</span></p>
            )}
            <div className="profile-actions">
              <button className='edit-profile' onClick={handleEditProfile}>Editar perfil</button>
              <button className='logout' onClick={handleLogout}>
                <img src="/images/cerrar-sesion.svg" alt="Logout" />
                <p>Salir</p>
              </button>
            </div>
          </div>
          <div className="dashboard-stats">
            <div className="bloque-secciones">
              <h3>Mis secciones</h3>
              <div className="bloque-seccion" onClick={handleGoBack}>
                <img src="/images/ticket-lord.svg" alt="Tickets" />
                {tokenInfo.role == "admin" ? <p>Administración</p> : <p>Mis tickets</p>}
                
              </div>
              <Link to={'/dashboard/servicios'}><div className="bloque-seccion">
                <img src="/images/contrato-lord.svg" alt="Servicios" />
                <p>Servicios contratados</p>
              </div></Link>

              <Link to={'https://agenciaadhoc.canny.io/'} target='_blank'><div className="bloque-seccion new-bloque">
                <img src="/images/star-lord.svg" alt="Vota mejoras" />
                <p>Vota o sugiere mejoras</p>
              </div></Link>
            </div>
            {cantidadTickets !== null && (
              <div className="bloque-stats">
                <img src="/images/ticket-lord.svg" alt="Trofeo" />
                <p>Tickets creados: <span>{cantidadTickets}</span></p>
              </div>
            )}
          </div>
        </div>
        <div className="dashboard-content">
          {renderContent()}
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
