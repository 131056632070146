import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import io from 'socket.io-client';
import PermisoDenegado from '../../components/PermisoDenegado';
import Loader from '../../components/Loader';
import BASE_URL from '../../config/config';

const Ticket = () => {
    const { identificador } = useParams();
    const [ticket, setTicket] = useState({ chat: [] });
    const [usuarioCreador, setUsuarioCreador] = useState(null);
    const [content, setContent] = useState('');
    const [socket, setSocket] = useState(null);
    const [tecnicos, setTecnicos] = useState([]);
    const [usuarioEscribiendo, setUsuarioEscribiendo] = useState(null);
    const [permisosVerificados, setPermisosVerificados] = useState(false);
    const [permisoDenegado, setPermisoDenegado] = useState(false);
    
    const chatRef = useRef(null);
    const bottomRef = useRef(null);  // Referencia al final del chat

    const token = localStorage.getItem("AuthToken");
    let tokenInfo = {};
    if (token) {
        tokenInfo = jwtDecode(token);
    }

    useEffect(() => {
        const verificarPermisos = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/obtener-ticket/${identificador}`);
                const data = response.data;

                if (data.ticket) {
                    data.ticket.chat = Array.isArray(data.ticket.chat) ? data.ticket.chat : JSON.parse(data.ticket.chat);
                    setTicket(data.ticket);

                    if (data.ticket.usuario_creador !== tokenInfo.usuario && tokenInfo.role !== 'admin') {
                        setPermisoDenegado(true);
                    }
                }

                const usuarioResponse = await axios.get(`${BASE_URL}/usuario/info/${data.ticket.usuario_creador}`);
                setUsuarioCreador(usuarioResponse.data.usuarios);
            } catch (error) {
                console.error('Error al obtener el ticket:', error);
            } finally {
                setPermisosVerificados(true);
            }
        };

        verificarPermisos();
    }, [identificador, tokenInfo.usuario, tokenInfo.role]);

    useEffect(() => {
        const fetchTecnicos = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/usuario/tecnicos`);
                setTecnicos(response.data.usuarios);
            } catch (error) {
                console.error('Error al obtener la lista de técnicos:', error);
            }
        };

        fetchTecnicos();
    }, []);

    // Función para hacer scroll hasta el final del chat
    const scrollToBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;  // Ajustar el scrollTop al scrollHeight para hacer scroll solo dentro del div .chat
        }
    };
        // Función para hacer scroll hasta el principio del chat
    const scrollToTop = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = 0;  // Ajustar el scrollTop al principio
        }
    };


    useEffect(() => {
        if (ticket.estado_del_ticket === "cerrado") {
            scrollToTop();  // Hacer scroll al principio si el ticket está cerrado
        } else if (ticket.chat.length > 0) {
            const timeout = setTimeout(() => {
                scrollToBottom();  // Realizar el scroll al final con un pequeño retraso si no está cerrado
            }, 100);
        
            return () => clearTimeout(timeout);  // Limpiar el timeout cuando se desmonta el componente o cambia el chat
        }
    }, [ticket.chat, ticket.estado_del_ticket]);
    


    const handleChange = (value) => {
        setContent(value);
        if (socket) {
            socket.emit('escribiendo', {
                identificador: identificador,
                usuario: tokenInfo.usuario,
                nombre: tokenInfo.nombre,
                apellidos: tokenInfo.apellidos,
            });
        }
    };

    const splitFiles = (filesString) => {
        return filesString.split(",");
    };

    const getFileName = (url, length = 10) => {
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        const extensionIndex = fileName.lastIndexOf('.');
        const name = fileName.substring(0, extensionIndex);
        const extension = fileName.substring(extensionIndex);
        const shortenedName = name.length > length ? name.substring(0, length) + '...' : name;
        return shortenedName + extension;
    };

    const getIconUrl = (file) => {
        const extension = file.split('.').pop().toLowerCase();
        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return file;
            case 'psd':
            case 'gif':
            case 'zip':
            case 'docx':
            case 'doc':
            case 'svg':
            case 'pdf':
            case 'xlsx':
            case 'csv':
                return `/images/${extension}.svg`;
            default:
                return '/images/almacenamiento-de-archivos.svg';
        }
    };

    const actualizarDpto = async (nuevoDpto) => {
        try {
            await axios.put(`${BASE_URL}/actualizar-dpto`, { identificador, dpto: nuevoDpto });
            setTicket((prevTicket) => ({
                ...prevTicket,
                dpto: nuevoDpto,
            }));
        } catch (error) {
            console.error('Error al actualizar el dpto:', error);
        }
    };

    const actualizarPrioridad = async (nuevaPrioridad) => {
        try {
            await axios.put(`${BASE_URL}/actualizar-prioridad`, { identificador, prioridad: nuevaPrioridad });
            setTicket((prevTicket) => ({
                ...prevTicket,
                prioridad: nuevaPrioridad,
            }));
        } catch (error) {
            console.error('Error al actualizar la prioridad:', error);
        }
    };

    const actualizarEstadoDelTicket = async (nuevoEstado) => {
        try {
            await axios.put(`${BASE_URL}/actualizar-estado_del_ticket`, { identificador, estado_del_ticket: nuevoEstado });
            setTicket((prevTicket) => ({
                ...prevTicket,
                estado_del_ticket: nuevoEstado,
            }));

            if (nuevoEstado === 'cerrado' && socket) {
                socket.emit('cerrarTicket', { identificador });
            }
        } catch (error) {
            console.error('Error al actualizar el estado del ticket:', error);
        }
    };

    const actualizarTecnicoAsignado = async (usuarioEncargado) => {
        try {
            // Llamar al endpoint para actualizar el técnico
            const response = await axios.put(`${BASE_URL}/actualizar-tecnico`, {
                identificador,
                usuario_encargado: usuarioEncargado
            });
    
            setTicket((prevTicket) => ({
                ...prevTicket,
                usuario_encargado: usuarioEncargado
            }));
    
            console.log('Técnico asignado correctamente:', response.data);
    
            // Solo notificar si el ticket no está sin asignar
            if (usuarioEncargado !== "sin_asignar") {
                // Llamar al endpoint para notificar al técnico
                const notificarResponse = await axios.post(`${BASE_URL}/notificar-tecnico`, {
                    identificador,
                    usuario_encargado: usuarioEncargado,
                    usuario_notificador: tokenInfo.usuario,
                    usuario_creador: ticket.usuario_creador,  // Pasar el usuario creador
                    nombre_cliente: ticket.nombre,
                    apellidos_cliente: ticket.apellidos,
                    email_cliente: ticket.email,
                    webs_del_cliente: ticket.webs_del_cliente,
                    asunto: ticket.asunto,  // Asunto del ticket
                    descripcion: ticket.descripcion // Descripción del ticket
                });
    
                console.log('Notificación enviada correctamente:', notificarResponse.data);
            } else {
                console.log('Ticket dejado sin asignar, no se envía notificación.');
            }
    
        } catch (error) {
            console.error('Error al asignar o notificar al técnico del ticket:', error.response ? error.response.data : error.message);
        }
    };
    
    

    const actualizarEstadoAdmin = async () => {
        try {
            const response = await axios.put(`${BASE_URL}actualizar-estado`, {
                identificador,
                estado_del_chat: 'respondido'
            });

            console.log('Estado del ticket actualizado correctamente:', response.data);
        } catch (error) {
            console.error('Error en la solicitud para actualizar el estado del ticket:', error.response ? error.response.data : error.message);
        }
    };

    const actualizarEstadoUser = async () => {
        try {
            const response = await axios.put(`${BASE_URL}/actualizar-estado`, {
                identificador,
                estado_del_chat: 'sin_responder'
            });

            console.log('Estado del ticket actualizado correctamente:', response.data);
        } catch (error) {
            console.error('Error en la solicitud para actualizar el estado del ticket:', error.response ? error.response.data : error.message);
        }
    };

    const enviarCorreo = async (role, content, email, usuario_encargado, usuario_creador, identificador) => {
        try {
            const response = await axios.post(`${BASE_URL}/send-email`, {
                role: tokenInfo.role,
                message: content,
                email: ticket.email,
                usuario_encargado, 
                identificador,
                usuario_creador,
            });
            console.log('Correo enviado:', response.data.message);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    };
    
    const enviarMensaje = async () => {
        if (socket && content.trim() !== '') {
            const mensajeData = {
                roomId: identificador,
                mensaje: content,
                identificador: identificador,
                usuario: tokenInfo.usuario,
                nombre: tokenInfo.nombre,
                apellidos: tokenInfo.apellidos,
                role: tokenInfo.role,
                fotoPerfil: tokenInfo.fotoPerfil,
            };
            socket.emit('mensaje', mensajeData);
            setContent('');
 
            if (tokenInfo.role === 'admin') {
                setTicket((prevTicket) => ({
                    ...prevTicket,
                    estado_del_chat: 'respondido'
                }));
                await actualizarEstadoAdmin();
            } else if (tokenInfo.role === 'user') {
                setTicket((prevTicket) => ({
                    ...prevTicket,
                    estado_del_chat: 'sin_responder'
                }));
                await actualizarEstadoUser();
            }
            scrollToBottom();

        }   
        await enviarCorreo(tokenInfo.role, content, ticket.email, ticket.usuario_encargado, ticket.usuario_creador, identificador);
    };

    useEffect(() => {
        const newSocket = io(`${BASE_URL}`);
        setSocket(newSocket);

        newSocket.on('ticketCerrado', (data) => {
            setTicket((prevTicket) => ({
                ...prevTicket,
                estado_del_ticket: 'cerrado'
            }));
        });

        return () => {
            newSocket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (socket && identificador) {
            socket.emit('joinRoom', {
                identificador: identificador,
                usuario: tokenInfo.usuario
            });
        }
    }, [identificador, socket]);

    useEffect(() => {
        if (socket) {
            socket.on('mensaje', (mensaje) => {
                setTicket((prevTicket) => ({
                    ...prevTicket,
                    chat: [...prevTicket.chat, mensaje]
                }));
            });

            let typingTimeout; // Declarar el timeout a nivel de componente

            socket.on('escribiendo', (data) => {
                if (data.usuario !== tokenInfo.usuario) {
                    setUsuarioEscribiendo(`${data.nombre} ${data.apellidos} está escribiendo...`);
                    
                    // Limpiar el timeout previo si existe
                    clearTimeout(typingTimeout);
            
                    // Configurar un nuevo timeout
                    typingTimeout = setTimeout(() => {
                        setUsuarioEscribiendo(null);
                    }, 3000); // Tiempo en ms
                }
            });
            
            
        }

        return () => {
            if (socket) {
                socket.off('mensaje');
                socket.off('escribiendo');
            }
        };
    }, [socket]);

    // Renderizado condicional basado en la verificación de permisos
    if (!permisosVerificados) {
        return <Loader/>;
    }

    if (permisoDenegado) {
        return <PermisoDenegado />;
    }

    return (
        <main className='ticket site-width'>
            {ticket && (
                <>
                    <div className='cabecera-ticket'>
                        <div className="cabecera-ticket-uno">
                            <img src="/images/boletos-de-avion.svg" alt="Icono chat" />
                            <div className="cabecera-ticket-texto">
                                {ticket.estado_del_ticket === 'cerrado' ? (
                                    <>
                                        <h1>Este ticket ha sido cerrado</h1>
                                        <p>Si crees que ha sido un error, vuelve a abrir otro ticket.</p>
                                    </>
                                ) : (
                                    <>
                                        <h1>{ticket.chat.some((msg) => msg.role === 'admin') ? 'Un técnico está revisando tu ticket' : 'Estamos procesando su ticket'}</h1>
                                        <p>{ticket.chat.some((msg) => msg.role === 'admin') ? 'Por favor, revise el chat regularmente' : 'En breve le asignaremos un técnico'}</p>
                                    </>
                                )}
                            </div>

                        </div>
                        <div className="cabecera-ticket-dos">
                            <div className={`estado-del-ticket ${ticket.estado_del_chat === "respondido" ? 'ticket-respondido' : ''}`}>
                                <p>
                                    {ticket.estado_del_chat === "sin_responder" ? 'Esperando respuesta' : ''}
                                    {ticket.estado_del_chat === "respondido" ? 'Respondido' : ''}
                                </p>
                            </div>
                            <div className="tecnico-asignado">
                                {ticket.usuario_encargado === "sin_asignar" ? <img src="/images/sin_asignar.svg" alt="Usuario sin asignar" /> : <img src="/images/asignado.svg" alt="Usuario asignado" />}

                                {tokenInfo.role === "admin" || tokenInfo.role === "tecnico" ?
                                    <select
                                        onChange={(e) => actualizarTecnicoAsignado(e.target.value)}
                                        value={ticket.usuario_encargado || "sin_asignar"}
                                    >
                                        <option value="sin_asignar">Sin asignar</option>
                                        {tecnicos.map((tecnico) => (
                                            <option key={tecnico.usuario} value={tecnico.usuario}>
                                                {tecnico.nombre} {tecnico.apellidos} (@{tecnico.usuario})
                                            </option>
                                        ))}
                                    </select> :
                                    <p>{ticket.usuario_encargado === "sin_asignar" ? 'Sin asignar' : `@${ticket.usuario_encargado}`}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="grid-ticket">
                        <div className="datos-ticket">
                            <div className="datos-principales">
                                <div className="bloque-datos">
                                    <p>Ticket ID</p>
                                    <p>#{ticket.identificador}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Nombre</p>
                                    <p>{ticket.nombre}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Email</p>
                                    <p>{ticket.email}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Teléfono</p>
                                    <p>{ticket.telefono}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Webs</p>
                                    <p>{ticket.webs_del_cliente}</p>
                                </div>
                                <div className="bloque-datos color-dato">
                                    <p>Dpto</p>
                                    {
                                        tokenInfo.role === 'admin' ?
                                        <select value={ticket.dpto} onChange={(e) => actualizarDpto(e.target.value)}>
                                            <option value="no_especificado">No especificado</option>
                                            <option value="desarrollo">Desarrollo</option>
                                            <option value="diseno">Diseño</option>
                                            <option value="marketing">Marketing</option>
                                            <option value="redes_sociales">Redes Sociales</option>
                                        </select> :
                                        ticket.dpto === 'no_especificado' ? <p className='no-especificado'>No especificado</p> :
                                            ticket.dpto === 'desarrollo' ? <p className="desarrollo">Desarrollo</p> :
                                                ticket.dpto === 'diseno' ? <p className="diseno">Diseño</p> :
                                                    ticket.dpto === 'marketing' ? <p className="marketing">Marketing</p> :
                                                        ticket.dpto === 'redes_sociales' ? <p className="redes-sociales">Redes Sociales</p> :
                                                            ''
                                    }
                                </div>
                                <div className="bloque-datos color-dato">
                                    <p>Prioridad</p>
                                    {
                                        tokenInfo.role === 'admin' ?
                                        <select value={ticket.prioridad} onChange={(e) => actualizarPrioridad(e.target.value)}>
                                            <option value="no_urgente">No urgente</option>
                                            <option value="urgente">Urgente</option>
                                        </select> :
                                        ticket.prioridad === 'no_urgente' ? <p className='sin-prioridad'>No Urgente</p> :
                                        ticket.prioridad === 'urgente' ? <p className="alta">Urgente</p> : ''
                                    }
                                </div>
                                <div className="bloque-datos color-dato">
                                    <p>Estado</p>
                                    {
                                        tokenInfo.role === 'admin' ?
                                        <select value={ticket.estado_del_ticket} onChange={(e) => actualizarEstadoDelTicket(e.target.value)}>
                                            <option value="abierto">Abierto</option>
                                            <option value="cerrado">Cerrado</option>
                                        </select> :
                                        ticket.estado_del_ticket === 'abierto' ? <p className='abierto'>Abierto</p> :
                                            ticket.estado_del_ticket === 'cerrado' ? <p className="cerrado">Cerrado</p> : ''
                                    }
                                </div>
                            </div>
                            <div className="datos-archivos">
                                <p>Archivos aportados</p>
                                {ticket.archivos ? (
                                    <div className='grid-archivos'>
                                        {splitFiles(ticket.archivos).map((file, index) => (
                                            <a target='_blank' href={file} key={index} className='archivo-ticket' download>
                                                <img src={getIconUrl(file)} alt={`Archivo ${getFileName(file)}`} />
                                                <p>{getFileName(file)}</p>
                                                <img className='descargar' src="/images/descargar.svg" alt="Descargar" />
                                            </a>
                                        ))}
                                    </div>
                                ) : <p className='no-archivos'>No se añadieron archivos</p>}
                            </div>
                        </div>
                        <div className="chat-ticket">
                            <p className='titulo-chat'>Chat</p>
                            <div className={`chat ${ticket.estado_del_ticket === 'cerrado' ? 'quitar-scroll' : ''}`} ref={chatRef}>
                            {ticket.estado_del_ticket === 'cerrado' && (
                                    <div className="overlay-chat">
                                        <p>Este ticket está cerrado y no se pueden enviar mensajes.</p>
                                    </div>
                                )}
                                {usuarioCreador && (
                                    <div className="mensaje-chat">
                                        <img src={usuarioCreador.fotoPerfil} alt="Foto de perfil" />
                                        <div className="mensaje-texto">
                                            <p className='mensaje-nombre'>{usuarioCreador.nombre} {usuarioCreador.apellidos}</p>
                                            <p className='mensaje-usuario'>@{usuarioCreador.usuario}</p>
                                            <p className='mensaje-descripcion' dangerouslySetInnerHTML={{ __html: ticket.descripcion }}></p>
                                            <p className='mensaje-fecha'>{new Date(ticket.createdAt).toLocaleString()}</p>
                                        </div>
                                    </div>
                                )}
                                {ticket.chat && Array.isArray(ticket.chat) && ticket.chat.map((msg, index) => (
                                    <div key={index} className="mensaje-chat">
                                        <img src={msg.fotoPerfil} alt="Foto de perfil" />
                                        <div className="mensaje-texto">
                                            <p className='mensaje-nombre'>{msg.nombre} {msg.apellidos}</p>
                                            <p className='mensaje-usuario'>@{msg.usuario}</p>
                                            <p className='mensaje-descripcion' dangerouslySetInnerHTML={{ __html: msg.texto }}></p>
                                            <p className='mensaje-fecha'>{new Date(msg.fecha).toLocaleString()}</p>
                                        </div>
                                    </div>
                                ))}
                                {/* Referencia al final del chat */}
                                <div className='bottomRef' ref={bottomRef}></div>

                            </div>
                            {ticket.estado_del_ticket !== 'cerrado' && (
                                <div className="enviar-mensaje">
                                    {usuarioEscribiendo && <div className="escribiendo"> <img src="/images/chat-escribiendo.svg" alt="Escribiendo" /><p>{usuarioEscribiendo}</p></div>}
                                    {
                                        tokenInfo.role == "admin" &&
                                            ticket.usuario_encargado === "sin_asignar" ? (
                                                <div className='chat-deshabilitado-admin'>
                                                    <div className='mensaje-deshabilitado'><p>Asigna el ticket a un técnico para habilitar el chat.</p></div>
                                                    <ReactQuill
                                                            theme="snow"
                                                            value={content}
                                                            onChange={handleChange}
                                                            
                                                        />
                                                    <button className='enviar-ticket' onClick={enviarMensaje}>Enviar</button>
                                                </div>
                                                ) : (
                                                    <>
                                                    <ReactQuill
                                                    theme="snow"
                                                    value={content}
                                                    onChange={handleChange}
                                                    
                                                />
                                                <button className='enviar-ticket' onClick={enviarMensaje}>Enviar</button>
                                                </>
                                                )
                                    }
                             </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </main>
    );
};

export default Ticket;
