import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Verificacion from '../../../components/Verificacion';
import BASE_URL from '../../../config/config';

const TicketManager = () => {
  const [cantidadTickets, setCantidadTickets] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [activeTab, setActiveTab] = useState('historial');
  const [currentPage, setCurrentPage] = useState(1);
  // Cantidad de tickets por página
  const [ticketsPerPage, setTicketsPerPage] = useState(20);
  const [searchText, setSearchText] = useState('');
  const [selectedDept, setSelectedDept] = useState('');
  const [selectedEstadoTicket, setSelectedEstadoTicket] = useState('');
  const [selectedEstadoChat, setSelectedEstadoChat] = useState('');
  const [selectedPrioridad, setSelectedPrioridad] = useState('');
  const [selectedUsuarioEncargado, setSelectedUsuarioEncargado] = useState('');
  const [tecnicos, setTecnicos] = useState([]);

  const token = localStorage.getItem("AuthToken");

  let tokenInfo = {};
  if (token) {
    tokenInfo = jwtDecode(token);
  }

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("AuthToken");
    window.location.reload();
  };

  let profilePicUrl = "/images/usuario.svg";
  if (tokenInfo.fotoPerfil && tokenInfo.fotoPerfil !== "none") {
    profilePicUrl = tokenInfo.fotoPerfil;
  }

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/tickets/total/?page=${currentPage}&limit=${ticketsPerPage}`);
        if (response.status === 200) {
          const sortedTickets = response.data.tickets.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setTickets(sortedTickets);
        } else {
          console.error('Error al obtener los tickets:', response.data.message);
        }
      } catch (error) {
        console.error('Error al obtener los tickets:', error);
      }
    };

    if (activeTab === 'historial') {
      fetchTickets();
    }
  }, [activeTab, currentPage, ticketsPerPage, tokenInfo.usuario]);

  const filterTickets = () => {
    const filtered = tickets.filter(ticket => {
      const matchesSearchText = 
        ticket.asunto.toLowerCase().includes(searchText.toLowerCase()) || 
        ticket.identificador.toString().includes(searchText);
  
      const matchesDepartment = 
        selectedDept ? 
        ticket.dpto.toLowerCase() === selectedDept.toLowerCase() :
        (tokenInfo.dpto_tecnico ? ticket.dpto.toLowerCase() === tokenInfo.dpto_tecnico.toLowerCase() : true);
  
      const matchesEstadoTicket = 
        selectedEstadoTicket === '' || ticket.estado_del_ticket.toLowerCase() === selectedEstadoTicket.toLowerCase();
  
      const matchesEstadoChat = 
        selectedEstadoChat === '' || ticket.estado_del_chat.toLowerCase() === selectedEstadoChat.toLowerCase();
  
      const matchesPrioridad = 
        selectedPrioridad === '' || ticket.prioridad.toLowerCase() === selectedPrioridad.toLowerCase();
  
      const matchesUsuarioEncargado = 
        selectedUsuarioEncargado === '' || 
        ticket.usuario_encargado.toLowerCase().includes(selectedUsuarioEncargado.toLowerCase());
  
      return matchesSearchText && matchesDepartment && matchesEstadoTicket && matchesEstadoChat && matchesPrioridad && matchesUsuarioEncargado;
    });
  
    setFilteredTickets(filtered);
  };
  
  
  useEffect(() => {
    filterTickets();
  }, [searchText, selectedDept, selectedEstadoTicket, selectedEstadoChat, selectedPrioridad, selectedUsuarioEncargado, tickets]);

  // Mover el cálculo de paginación después del filtrado
  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);

  const totalPages = Math.ceil(filteredTickets.length / ticketsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const fetchTecnicos = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/usuario/tecnicos`);
            setTecnicos(response.data.usuarios);
        } catch (error) {
            console.error('Error al obtener la lista de técnicos:', error);
        }
    };

    fetchTecnicos();
}, []);


  if (tokenInfo.estado === "verificar") {
    return <Verificacion />;
  }

  return (
    <main className='site-width'>
        <div className="dashboard-content">
          <h2>Gestor de Tickets</h2>
            <p>Administra los tickets del sistema según tu departamento.</p>
       

          <div className="tabcontent">
            <div className='grid-historial'>
              <div className="filtro-tickets-admin">
                <input type="text" placeholder="Búsqueda por identificador o asunto..." value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <div className="filtro-tickets-opciones">
                <select 
                  value={tokenInfo.dpto_tecnico || selectedDept} 
                  onChange={(e) => setSelectedDept(e.target.value)} 
                  disabled={!!tokenInfo.dpto_tecnico} // Deshabilitar si el token tiene departamento
                >
                  <option value="">Todos los departamentos</option>
                  <option value="no_especificado">Sin Especificar</option>
                  <option value="diseno">Diseño</option>
                  <option value="marketing">Marketing</option>
                  <option value="desarrollo">Desarrollo</option>
                  <option value="redes_sociales">Redes Sociales</option>
                </select>

                <select value={selectedEstadoTicket} onChange={(e) => setSelectedEstadoTicket(e.target.value)}>
                  <option value="">Todos los estados del ticket</option>
                  <option value="abierto">Abierto</option>
                  <option value="cerrado">Cerrado</option>
                </select>
                <select value={selectedEstadoChat} onChange={(e) => setSelectedEstadoChat(e.target.value)}>
                  <option value="">Todos los estados del chat</option>
                  <option value="sin_responder">Sin Responder</option>
                  <option value="respondido">Respondido</option>
                </select>
                <select value={selectedPrioridad} onChange={(e) => setSelectedPrioridad(e.target.value)}>
                  <option value="">Todas las prioridades</option>
                  <option value="no_urgente">No urgente</option>
                  <option value="urgente">Urgente</option>
                </select>

                <select value={selectedUsuarioEncargado} onChange={(e) => setSelectedUsuarioEncargado(e.target.value)}>
                  <option value="">Todos los técnicos</option>
                  <option value="sin_asignar">Sin Asignar</option>
                  {tecnicos.map((tecnico) => (
                    <option key={tecnico.id} value={tecnico.nombre}>
                      {tecnico.nombre}
                    </option>
                  ))}
                </select>
                </div>
               

              </div>
              <div className='table-overflow'>
              <table className='tickets-table'>
                <thead>
                  <tr>
                    <th>Identificador</th>
                    <th>Nombre completo</th>
                    <th>Asunto</th>
                    <th>Estado</th>
                    <th>Departamento</th>
                    <th>Chat</th>
                    <th>Prioridad</th>
                    <th>Fecha</th>
                    <th>Asignado a</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTickets.length === 0 ? (
                    <tr>
                      <td colSpan="11" className='no-tickets'>No se han encontrado tickets</td>
                    </tr>
                  ) : (
                    currentTickets.map((ticket, index) => (
                      <tr key={index}>
                        <td><Link to={`/ticket/${ticket.identificador}`}>#{ticket.identificador}</Link></td>
                        <td>{ticket.nombre} {ticket.apellidos}</td>
                        <td>
                            <Link to={`/ticket/${ticket.identificador}`}>
                                {ticket.asunto.split(' ').slice(0, 5).join(' ')}{ticket.asunto.split(' ').length > 5 ? '...' : ''}
                            </Link>
                        </td>
                        <td>
                          {
                            ticket.estado_del_ticket === 'abierto' ? <p className='abierto'>Abierto</p> :
                            ticket.estado_del_ticket === 'cerrado' ? <p className="cerrado">Cerrado</p> : ''
                          }                          
                        </td>
                        <td>
                          {
                            ticket.dpto === 'no_especificado' ? <p className='no-especificado'>Sin especificar</p> :
                            ticket.dpto === 'desarrollo' ? <p className="desarrollo">Desarrollo</p> :
                            ticket.dpto === 'diseno' ? <p className="diseno">Diseño</p> :
                            ticket.dpto === 'marketing' ? <p className="marketing">Marketing</p> :
                            ticket.dpto === 'redes_sociales' ? <p className="redes-sociales">Redes Sociales</p> :
                            ''
                          }
                        </td>

                        <td>
                          {
                            ticket.estado_del_chat === 'sin_responder' ? <p className='sin-responder'>Sin Responder</p> :
                            ticket.estado_del_chat === 'respondido' ? <p className="respondido">Respondido</p> : ''
                          }                          
                        </td>
                        <td>
                          {
                            ticket.prioridad === 'no_urgente' ? <p className='sin-prioridad'>No Urgente</p> :
                            ticket.prioridad === 'urgente' ? <p className="alta">Urgente</p> : ''
                          }
                        </td>
                        <td>{new Date(ticket.createdAt).toLocaleDateString()}</td>
                        <td className='assignee'>
                          {ticket.usuario_encargado === "sin_asignar" ? 
                            <div className='asignado'>
                              <img src="/images/sin_asignar.svg" alt="Sin asignar" />
                              <p>Sin asignar</p>
                            </div>
                            :
                            <div className='asignado'>
                              <img src="/images/asignado.svg" alt={ticket.usuario_encargado} />
                              <p>{ticket.usuario_encargado}</p>
                            </div>
                          }
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              </div>
              {filteredTickets.length !== 0 && (
                <div className="pagination">
                  <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Anterior</button>
                  <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente</button>
                </div>
              )}
            </div>
          </div>
        </div>
    </main>
  );
};

export default TicketManager;
