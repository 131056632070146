import React from 'react'

const Error404 = () => {
  return (
    <main className='site-width'>
      <div className='error-404'>
        <h1>¡Oops, Error 404!</h1>
        <p>La página que buscas no existe.</p>
         <a href='/'>Volver al Inicio</a>
      </div>
    </main>
  )
}

export default Error404
