import React from 'react'

const PermisoDenegado = () => {
  return (
    <main className='ticket-denegado site-width'>
    <div className="acceso-denegado">
        <img src="/images/boton-eliminar.svg" alt="Denegado" />
        <h2>Acceso Denegado</h2>
        <p>No tienes permiso para ver este ticket.</p>
    </div>
    </main>
  )
} 

export default PermisoDenegado
